import React , {useEffect} from "react";

import Scrollspy from "react-scrollspy";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Logo from "../../Assets/Images/Full logo.png";
// hero
import hero from "../../Assets/Images/hero-hb.png";

// import habhero from "../../Assets/Images/haboob-hero.svg";

// about
import v1 from "../../Assets/Images/v1.jpg";
import v2 from "../../Assets/Images/v2.jpg";
import smalllogo from "../../Assets/Images/small-logo.png";

// team
import Slider from "react-slick";
// import smalllogo from '../../Assets/Images/small-logo.png';
import t1 from "../../Assets/Images/t1.svg";
import t2 from "../../Assets/Images/t2.svg";
import t3 from "../../Assets/Images/t3.svg";
import t4 from "../../Assets/Images/t4.svg";
import t5 from "../../Assets/Images/t5.svg";
import t6 from "../../Assets/Images/t6.svg";

import circle from '../../Assets/Images/hab-circle.svg';
import circle2 from '../../Assets/Images/hb-circle2.svg';


// sponsers
import smart from "../../Assets/Images/smart-logo.svg";
import orecal from "../../Assets/Images/Oracle-Logo.svg";
import ithar from "../../Assets/Images/ithra-logo.svg";
import inschool from "../../Assets/Images/inschool-logo.svg";
import Aramco from "../../Assets/Images/Saudi-Aramco-Logo.svg";
import mesk from "../../Assets/Images/meskScool-logo.svg";
import Awn from "../../Assets/Images/ha-Awn-sp.svg";
import sad from "../../Assets/Images/hb-sad-sp.svg";
import bay from "../../Assets/Images/hb-bay-sp.svg";
import Aut from "../../Assets/Images/hb-Aut-sp.svg";






import AOS from "aos";
import 'aos/dist/aos.css';

import "./Navbar.css";

const Navbar = () => {
  useEffect(()=>{
    AOS.init({duration: 2000});
  },[])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 769,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false },
      },
      {
        breakpoint: 425,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false },
      },
    ],
  };
  const settingsicons = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 769,
        settings: { slidesToShow: 2, slidesToScroll: 2, infinite: true,
          speed: 2000,
          autoplaySpeed: 1000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 425,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: true,
          speed: 2000,
          autoplaySpeed: 1000,
          cssEase: "linear",
         },
      },
    ],
  };
  return (
    <>
      {/* nav-bar ===============================*/}
      <section style={{ marginBottom: "80px" }} className="navbarsection">
        <nav
          id="navbar-example"
          class="navbar navbar-expand-lg navbar-light  fixed-top shadow-sm"
          
        >
          <div className="container" data-aos="fade-down">
            <a class="navbar-brand" href="#hero">
              <img className="logo " src={Logo} alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0"> */}
              <Scrollspy
                className=" ms-auto mb-2 mb-lg-0 scrollbox"
                items={["hero", "about", "team", "sponsers"]}
                currentClassName="active"
              >
                <li className="nav-item px-3">
                  <a className="nav-link" aria-current="page" href="#hero">
                    Home
                  </a>
                </li>
                <li className="nav-item px-3">
                  <a className="nav-link" href="#about">
                    About
                  </a>
                </li>
                <li className="nav-item px-3">
                  <a className="nav-link" href="#team">
                    Our team
                  </a>
                </li>
                <li className="nav-item px-3">
                  <a className="nav-link" href="#sponsers">
                    Sponsors
                  </a>
                </li>
              </Scrollspy>
              {/* </ul> */}

              {/* icons */}

              <ul className="me-auto mb-2 mb-lg-0  icons d-flex">
                <a
                  href="https://www.linkedin.com/in/haboob-f1-a4b405264"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-linkedin"></i>
                </a>
                <a
                  href="https://www.instagram.com/haboob_ksa/"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-instagram"></i>
                </a>
                <a
                  href="https://www.tiktok.com/@haboob.f1sa?_t=8zwuq3chm78&_r=1"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-tiktok"></i>
                </a>
                <a
                  href="https://twitter.com/haboobf1?s=21&t=0YJ3iPF_r5U85NcnzGeGPQ"
                  className="me-3"
                >
                  <i className="icon fa-brands fa-twitter"></i>
                </a>
              </ul>
            </div>
          </div>
        </nav>
      </section>
      {/* hero =======================================*/}
      <section id="hero" className="herosection py-5">
        <div className="container content text-center ">
          <img className="hero-img w-75 my-3" src={hero} alt="hero" />
          <p data-aos="fade-left">
            faster than a <span>Gust</span> of wind
          </p>
        </div>
      </section>
      {/* about-section =============================*/}
      <section
        id="about"
        className="ourvision"
        style={{ paddingTop: "50px", paddingBottom: "350px" }}
      >
        <div className="container content py-5">
          <div className="row">
            {/* data */}
            <div className="col-md-6">
              <div className="data" data-aos="fade-right">
                <div className="title-v py-4">
                  <div className="title-font">
                    <span>
                      <img src={smalllogo} alt="ss" style={{ width: "50px" }} />
                    </span>
                    ur vision
                  </div>
                  <div className="line-title my-2"></div>
                </div>
                <p>
                  Our team, Habbob, aspires to make an impact in the Formula 1
                  In Schools competition, and become an international example of
                  excellence and innovation.
                </p>
              </div>
            </div>
            {/* image-1 */}
            <div className="col-md-6">
              <div className="img-data mx-2 text-end" data-aos="fade-left">
                <img src={v1} alt="v1" className="w-75" />
              </div>
            </div>

            {/* image-2 */}
            <div className="col-md-6 mt-5">
              <div className="img-data mx-2 text-start my-5" data-aos="fade-right">
                <img src={v2} alt="v2" className="w-75" />
              </div>
            </div>

            <div className="col-md-6 mt-4">
              <div className="data" data-aos="fade-left">
                <div className="title-v py-4">
                  <div className="title-font">
                    <span>
                      <img src={smalllogo} alt="ss" style={{ width: "50px" }} />
                    </span>
                    ur mission{" "}
                  </div>
                  <div className="line-title my-2"></div>
                </div>
                <p>
                  We pledge to work diligently to develop our knowledge and
                  skills in engineering, Enterprise, and design, in order to
                  build a successful, competitive team. We will strive to ensure
                  that our efforts are fuelled by enthusiasm and passion, and
                  that our team is guided by principles of integrity and
                  respect. Our ultimate goal is to bring pride and recognition
                  to the Kingdom of Saudi Arabia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
            {/* vidoe */}
            {/* <section className="vidoe">
          <video loop="true" autoplay="autoplay" controls muted className="w-100">
              <source src={cpvideo} type="video/mp4"/>
          </video>
      </section> */}
      {/* coming */}
      <section className="coming p-5">
        <h3 className="p-4" data-aos="fade-down">Habbah is coming soon </h3>
      </section>
      {/* Team=============================== */}
      <section id="team" className="team py-3">
        <div className="title py-5 text-center">
          <div className="title-font">
            <span>
              <img src={smalllogo} alt="ss" style={{ width: "50px" }} />
            </span>
            ur Team
          </div>
          <div className="line-title my-2"></div>
        </div>
        <Slider {...settings} className="slideritem container py-5">
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t1} alt="" className="img-t w-50" />
              <h3>Rose Asseri</h3>
              <label>Team Management</label>
              <p>
                Rose makes that the project is not only smoothly acing the
                competition's standrads but to furthermore achieve the team's
                vision and keeping the tewam's energy always high and thriving
                exceptionally when it comes to all of haboob's graphic needs.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t2} alt="" className="img-t w-50" />
              <h3>Faisal Al Saeed</h3>
              <label>Finanace &sponsership</label>
              <p>
                Faisal Al saeed is an idea candidate for the marketing
                department because of this extensive experience and knowledge in
                the field. with faisal Alsaeed on the marketing team , the team
                will benfite from his innovative ideas' problem-solving skills'
                and cutting-edge strategies.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t3} alt="" className="img-t w-50" />
              <h3>Reema AL-Jasser</h3>
              <label>Project manager</label>
              <p>
                Time manager, precise, passionate and organized these are the
                main outstanding characteristics in Reema, the project manager
                Reema always has a clear goal to strive for and achieve. she
                will help Haboob be a marvelous team with amazing outcome and
                great successes{" "}
              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t4} alt="" className="img-t w-50" />
              <h3>Jood Al-Hazmi</h3>
              <label>Marketing & enterprise</label>
              <p>
                When we think of strategic thinking we think of jood. she seeks
                to improve haboobs identity and set goals to reach.It is her job
                to raise funds and get maximum media exposure.she also searches
                for potential sponsors that can help haboob be known worldwide.
              </p>
            </div>
          </div>
          {/* 5 */}
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t5} alt="" className="img-t w-50" />
              <h3>Haya Al-saadoun</h3>
              <label>Manufacturing</label>
              <p>
                She has taken on the job of not only putting the parts
                together's but also upgrading and rectifying any rectifying
                flaws. testing the models' developing solutions and concept's
                and constructing all models are parts of her role.
              </p>
            </div>
          </div>
          {/* 6 */}
          <div className="cardbox">
            <div className="inside py-5 px-3 text-center">
              <img src={t6} alt="" className="img-t w-50" />
              <h3>Mashari Al-Anazi</h3>
              <label>Engineering design</label>
              <p>
                Mashari has great knowledge in physics' mathematics' and design.
                He is Haboob's Design Engineer he will be using the fusion 360
                and CFD to design an fi car eith an amazing performance that
                allows Haboob to win and succeed in the copetition.
              </p>
            </div>
          </div>
        </Slider>
      </section>
      {/* sponsers=============================== */}
      <section id="sponsers" className="sponsers py-3 text-center">
          <img src={circle} alt="" className="circle1-sp"/>
          <img src={circle2} alt="" className="circle2-sp"/>



        <div className="sliders container">
          <div className="title-v py-5 text-center">
            <div className="title-font">
              <span>
                <img src={smalllogo} alt="ss" style={{ width: "50px" }} />
              </span>
              ur Sponsors
            </div>
            <div className="line-title my-2"></div>
          </div>
          <div className="slider-trucks py-5">
        {/* test */}

        <Slider {...settingsicons} className="slideritem container py-5">
          <div className="cardbox">
            <div className="inside py-5 px-2 text-center">
              <img src={orecal} alt="" style={{ width: "60%" }} />
            </div>
          </div>
          {/* 2 */}
          <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img src={smart} alt="" style={{ width: "90%" }} />

            </div>
          </div>
          {/* 3 */}
          <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="mb-2"
                src={ithar}
                alt=""
                style={{ width: "40%" }}
              />
            </div>
          </div>
          {/* 4 */}
          <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="mt-2"
                src={inschool}
                alt=""
                style={{ width: "50%" }}
              />
            </div>
          </div>
          {/* 5 */}
          <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img src={Aramco} alt="" style={{ width: "60%" }} />

            </div>
          </div>
          {/* 6 */}
          <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="my-2"
                src={mesk}
                alt=""
                style={{ width: "70%" }}
              />
            </div>
          </div>
                    {/* 7 */}
                    <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="my-2"
                src={Awn}
                alt=""
                style={{ width: "40%" }}
              />
            </div>
          </div>
                              {/* 8 */}
                              <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="my-2"
                src={sad}
                alt=""
                style={{ width: "70%" }}
              />
            </div>
          </div>
                              {/* 9 */}
                              <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="my-2"
                src={bay}
                alt=""
                style={{ width: "40%" }}
              />
            </div>
          </div>
                              {/* 10 */}
                              <div className="cardbox">
          <div className="inside py-5 px-3 text-center">
          <img
                className="my-2"
                src={Aut}
                alt=""
                style={{ width: "70%" }}
              />
            </div>
          </div>
        </Slider>
        {/* test */}
              
            {/* <div className="slide">
              <img src={orecal} alt="" style={{ width: "60%" }} />
            </div>
            <div className="slide">
              <img src={smart} alt="" style={{ width: "90%" }} />
            </div>
            <div className="slide">
              <img
                className="mb-2"
                src={ithar}
                alt=""
                style={{ width: "40%" }}
              />
            </div>
            <div className="slide">
              <img
                className="mt-2"
                src={inschool}
                alt=""
                style={{ width: "50%" }}
              />
            </div>
            <div className="slide">
              <img src={Aramco} alt="" style={{ width: "60%" }} />
            </div>
            <div className="slide">
              <img
                className="my-2"
                src={mesk}
                alt=""
                style={{ width: "70%" }}
              />
            </div> */}
          </div>

          <div className="data-info py-5">
            <p>
              The F1 IN SCHOOLS Logo, F1, FORMULA 1, FIA FORMULA ONE WORLD
              CHAMPIONSHIP,GRAND PRIX and related marks are trademarks of
              Formula One Licensing BV, a Formula One group company. All rights
              reserved
            </p>
          </div>
        </div>
        {/* ============================fqa==================== */}
        <div className="img-line"></div>
        <div className="faq">
          <div className="title py-5 text-center">
            <div className="title-font">
                <span>
                <img src={smalllogo} alt="ss" style={{ width: "50px" }} />
              </span>
              FAQ</div>
            <div className="line-title my-2"></div>
          </div>
          {/* faq-qu */}
          <div className="container">
            <MDBContainer className="mt-5" style={{ maxWidth: "1000px" }}>
              <MDBAccordion alwaysOpen initialActive={1}>
                <MDBAccordionItem
                  collapseId={1}
                  headerTitle="What is F1 in Schools?"
                >
 F1 in Schools is a worldwide STEM competition where teams of 3-6 students compete to create a miniature F1 car, with also building an identity for a team.
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={2}
                  headerTitle="What is the material of the car?"
                >
                The car is primarily a block that is taken from the competition, teams can create front, rear wings, and wheels in any material they desire

                </MDBAccordionItem>
                <MDBAccordionItem collapseId={3} headerTitle="How can I join?">
                You can start with asking your school if there is a chance to
                  join
                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="How long is the competition?"
                >
                  Depends on how long you stay, there is an age limit of 11-19, but a set of competitions beginning with regionals, nationals, and finally the world final would take about 9 months to complete

                </MDBAccordionItem>
                <MDBAccordionItem
                  collapseId={3}
                  headerTitle="What does the winner of f1 in schools get?"
                >
                  The winning team receives the F1 in Schools World Championship Trophy as well as scholarships for engineering degrees at UCL, the University of Huddersfield and City, University of London, teams can also win much more from different aspects

                </MDBAccordionItem>
              </MDBAccordion>
            </MDBContainer>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
